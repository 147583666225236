import React from "react";
import "./App.css";
import Navbar from "./global/Navbar/Navbar";
import { RouterProvider } from "react-router-dom";
import router from "./router/router";
import Footer from "./global/Footer/Footer";
import { AppContext } from "./context/Context";
import { HelmetProvider } from "react-helmet-async";
import {Helmet} from "react-helmet-async";

function App() {
  //init states
  const [width, setWidth] = React.useState(window.innerWidth);
  const { isMobile, setIsMobile } = React.useContext(AppContext);

  //check if on mobile (responsive)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  setIsMobile(width <= 768); // use this in your components to check if on mobile device for js scripts

  const metaobj = document.getElementsByTagName("META");
  // metaobj.forEach(element => {
  //   console.log(element)
  // });

  console.log(metaobj[0])
  console.log(metaobj[1])
  console.log(metaobj[2])
  console.log(metaobj[3])

  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <meta name="description" content="Apply for the 12th edition of the EESTEC Olympics hackathons!"/>
          <link rel="icon" href="./icon.png" />
        </Helmet>
        <Navbar mobile={isMobile} />
        <RouterProvider router={router} />
        <div style={{ height: "150px" }}></div>
        {/* this is approximately the height of the footer */}
        <Footer />
      </HelmetProvider>
    </div>
  );
}

export default App;
