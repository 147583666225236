import React from "react";
import "./Navbar.css";

import locale from "../../locale/locales.json";
import logo from "../../svg/EO12.svg";
import { AppContext } from "../../context/Context"

export default function Navbar({ mobile }) {
  //init states
  
  const [navOpen, setNavOpen] = React.useState(false);
  const { lang, setLang } = React.useContext(AppContext);

  //init const
  const MOBILE_ANIM_DELAY = 40; // controls the delay of the individual navbar rows on mobile. lower is faster

  if (!mobile)
    return (
      <nav className="navbar-desktop">
        <a href="/">
          <img src={logo} alt="Home" />
        </a>
        <ul className="navbar-desktop-list">
          {locale[lang].global.navbar.map((el, index) => {
            console.log(window.location.pathname === `/${el.page}`);
            return (
              <li
                className={`navbar-desktop-li ${
                  window.location.pathname === `/${el.page}`
                    ? "navbar-desktop-selected"
                    : ""
                } `}
                key={index}
              >
                <a href={el.page}>{el.title}</a>
              </li>
            );
          })}
          <li className="navbar-desktop-li navbar-desktop-selected">
            <a href={locale["both"].global.formLink} target="_blank">
              {locale[lang].global.signup.text}
            </a>
          </li>
        </ul>
        <div className="langChangerContainer">
          <button
            className={`langChangerButton ${lang == "ro" ? "selected" : ""}`}
            onClick={() => {
              setLang("ro")
            }}
          >
            RO
          </button>
          <button
            className={`langChangerButton ${lang == "en" ? "selected" : ""}`}
            onClick={() => {
              setLang("en")
            }}
          >
            EN
          </button>
        </div>
      </nav>
    );
  else {
    return (
      <nav className="navbar-mobile">
        <div className={`navbar-mob-first ${navOpen == true ? "navbar-mob-first-selected" : ""}`}>
          <a href="/" className="logo-nav-mobile">
            <img src={logo} alt="Home"></img>
          </a>
          <input
            type="checkbox"
            role="button"
            aria-label="Display the menu"
            className="navbar-burger"
            onClick={() => {
              setNavOpen((prevState) => !prevState);
            }}
          ></input>
        </div>
        <ul
          className={`navbar-mob-ul ${navOpen == true ? "navbar-mob-vis" : "invisible"}`}
        >
          {locale[lang].global.navbar.map((el, index) => {
            console.log(window.location.pathname === `/${el.page}`);
            return (
              <li
                className={`navbar-mob-li ${
                  window.location.pathname === `/${el.page}`
                    ? "navbar-mob-selected"
                    : ""
                } `}
                style={{ transitionDelay: `${index * MOBILE_ANIM_DELAY}ms` }}
                key={index}
              >
                <a href={el.page}>{el.title}</a>
              </li>
            );
          })}
          <li
            className="navbar-mob-li navbar-mob-selected"
            style={{
              transitionDelay: `${locale[lang].global.navbar.length * MOBILE_ANIM_DELAY}ms`,
            }}
          >
            <a href={locale["both"].global.formLink} target="_blank">
              {locale[lang].global.signup.text}
            </a>
          </li>
          <li className="lang-changer-mob-container" style={{
              transitionDelay: `${(locale[lang].global.navbar.length+1) * MOBILE_ANIM_DELAY}ms`,
            }}>
            <button
              className={`langChangerButton ${lang == "ro" ? "selected" : ""}`}
              onClick={() => {
                setLang("ro")
              }}
            >
              RO
            </button>
            <button
              className={`langChangerButton ${lang == "en" ? "selected" : ""}`}
              onClick={() => {
                setLang("en");
              }}
            >
              EN
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}
